import { Icon } from "@iconify/react";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Slide } from "@mui/material";
import React, { useState } from "react";
import Fogg from '../Assets/Images/fogg.png';
import { createNewEntity } from "../Network/Integration";
import { validateEmail } from "../Network/Utils";
  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const PopUpDemo = ({style, setIsDialogDisabled}) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({success: false, message: ''});
    const [errorMsg, setErrorMsg] = useState("");
    const handleClose = () => {
      // setOpen(!open);
      setIsDialogDisabled(true);
    }
  
    const onSubmit = () => {
      if(validateEmail(email)){
        setIsLoading(true);
        createNewEntity({type: "demo", email: email}, setEmail, setIsLoading, setError)
      }else{
        setErrorMsg("Enter a valid email")
      }
    }
    return (
        <Dialog
          open={true}
          maxWidth="md"
          fullWidth
          TransitionComponent={Transition}
          aria-hidden="true"
          onClose={handleClose}
          // aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className='flex-sb' style={{ background: "linear-gradient(30deg, #362087, #130641)"}}>
              <span>{" "}</span>
              <Icon icon={"eva:close-fill"} onClick={() => handleClose()} style={{fontSize: 30, cursor: "pointer", color: "whitesmoke" }}/>
          </DialogTitle>
          <DialogContent className='flex-sb' style={{ background: "linear-gradient(30deg, #362087, #130641)", color: "white"}}>
              {error?.message?.length > 0 ? <div>
                <b style={{ fontSize: 28}}>{error.message}</b>
                {error.success == true ? <p style={{ fontSize: 18, color: "whitesmoke"}}>We will reach out as soon as possible. Thank you </p> : <> 
                  <div className='d-flex' style={{overflow: "visible", flexWrap: "wrap", gridGap: 10}}>
                      <input value={email} style={{ border: "1px solid rgb(85,111,222)",color: "whitesmoke", width: "60%", background: "transparent", padding: 10, borderRadius: 5, height: 40 }} placeholder="Your email" onChange={(e) => setEmail(e.target.value) } />
                      <Button onClick={() => onSubmit()} variant="contianed" style={{ background: "rgb(85,111,222)", width: "auto"}} size="small" disabled={isLoading || email?.length == 0}>{isLoading ? "I know..." :"Request a demo" }</Button>
                  </div>
                  {errorMsg?.length > 0 && <p style={{ fontSize: 14, color: "red"}}>{errorMsg}</p>}
                  </>
                }
            </div>
            : 
            <div>
              <b style={{ fontSize: 28}}>Don't miss out on what we have to offer</b>
              <p style={{ fontSize: 18, color: "whitesmoke"}}>Leave your email and we will reach out to schedule a demo with you</p>
              <div className='d-flex' style={{overflow: "visible", flexWrap: "wrap", gridGap: 10}}>
                  <input value={email} style={{ border: "1px solid rgb(85,111,222)",color: "whitesmoke", width: "60%", background: "transparent", padding: 10, borderRadius: 5, height: 40 }} placeholder="Your email" onChange={(e) => {setErrorMsg(""); setEmail(e.target.value) }} />
                  <Button onClick={() => onSubmit()} variant="contianed" style={{ background: "rgb(85,111,222)", width: "auto"}} size="small" disabled={isLoading || email?.length == 0}>{isLoading ? "I know..." :"Request a demo" }</Button>
              </div>
              {errorMsg?.length > 0 && <p style={{ fontSize: 14, color: "red"}}>{errorMsg}</p>}
            </div>
              }
            <div className="fogg_img">
              {/* <div id="shadow" className="fogg-css-shadow"> </div> */}
              <img src={Fogg} className="" height={320} style={{position: "relative", marginTop: 20, zIndex: 1}}/>
            </div>
          </DialogContent>
        </Dialog>
    );
}