import { Button, Checkbox, Slider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MetaDecorator from '../components/MetaDecorder';
import SwitchCustom from '../components/SwitchCustom';
import Logo1 from '../Assets/Images/logo.png';
import { Icon } from '@iconify/react';
import { PopUpDemo } from '../components/PopupDemo';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function valuetext(value) {
    return `${value}°C`;
}
function Pricing() {

    const [isDialogDisabled, setIsDialogDisabled] = useState(true);

    useEffect(() => {
        // setTimeout(() => {
        //     setIsDialogDisabled(false);
        // }, 1000)
    }, []);

    return (
        <div className="main-dashboard-wrapper my-5">
            <MetaDecorator title={"Vernacle Pricing - Vernacle translation"} description={"Singup and Start free trail for 14 days. Choose the package which suits you"} />
            {!isDialogDisabled && <PopUpDemo id="dailog-cl" setIsDialogDisabled={setIsDialogDisabled} />}
            <div className="container px-4 py-2 my-2 text-center">
                <h1 className="display-5 fw-bold c-balck">Ready to start with <br /><span className='icon-title'>vernacle</span> ?</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4 c-balck-600">Singup and Start free trail for 14 days. Choose the package which suits you..</p>
                    <div className="d-grid gap-2 d-sm-flex cust-swt justify-content-sm-center">
                        <div className='div-label-switch'>
                            Billed Monthly
                        </div>
                        <div className='px-4'>
                            <SwitchCustom />
                        </div>
                        <div className='div-label-switch'>
                            Billed Yearly
                        </div>
                    </div>
                </div>
                <div className="container-default my-5 home-features w-container">
                    <div className="d-flex flex-column my-5 jsc flex-md-row gap-3">
                        <div className="card home-feature pricing selected">
                            <div className="card-home-feature-type">
                                <h3 className="title card-home-feature c-balck">Free access</h3>
                                <p className="paragraph card-home-feature c-balck-600">Any individual can try.</p>
                            </div>
                            <div className="card-home-feature-pricing">
                                <h3 className="title-p  c-balck"> $ 0</h3>
                                <p className="paragraph-p c-balck-600">Start a free trail for 14 days. Access all of our feature and enjoy</p>
                            </div>
                            <div className="card-home-feature-pricing-button">
                                <button className='btn-crt'>Create a free account</button>
                            </div>
                            <div className="card-home-feature-pricing-bullet">
                                <ul>
                                    <li className='yes-p'>Upto 2 projects</li>
                                    <li className='yes-p'>Upto 2 language support</li>
                                    <li className='yes-p'>Add unlimited translation</li>
                                    <li className='yes-p'>Create team & Assign roles</li>
                                    <li className='yes-p'>Download report</li>
                                    <li className='yes-p'>Access on web platform</li>
                                    <li className='yes-p'>Access on Android platform</li>
                                    <li className='yes-p'>Access on IOS platform</li>
                                    <li className='no-p'>Figma localization plugin</li>
                                    <li className='no-p'>Custom features</li>
                                    <li className='no-p'>Machine Translation</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card home-feature pricing n-selected">
                            <div className="card-home-feature-type">
                                <h3 className="title card-home-feature c-balck">Basic access</h3>
                                <p className="paragraph card-home-feature c-balck-600">For the basic.</p>
                            </div>
                            <div className="card-home-feature-pricing">
                                <h3 className="title-p c-balck" style={{ fontSize: 17 }}> $ Calculating...</h3>
                                <p className="paragraph-p c-balck-600">Start the basic plan and pay $29 per month. (Save on yearly)</p>
                            </div>
                            <div className="card-home-feature-pricing-button">
                                <button className='btn-crt'>Get Started</button>
                            </div>
                            <div className="card-home-feature-pricing-bullet">
                                <ul>
                                    <li className='yes-p'>Upto 4 projects</li>
                                    <li className='yes-p'>Upto 3 language support</li>
                                    <li className='yes-p'>500 hosted string keys</li>
                                    <li className='yes-p'>Create team & Assign roles</li>
                                    <li className='yes-p'>Download report</li>
                                    <li className='yes-p'>Access on web platform</li>
                                    <li className='yes-p'>Access on Android platform</li>
                                    <li className='yes-p'>Access on IOS platform</li>
                                    <li className='yes-p'>Figma localization plugin</li>
                                    <li className='no-p'>Custom features</li>
                                    <li className='no-p'>Machine Translation</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card home-feature pricing n-selected">
                            <div className="card-home-feature-type">
                                <h3 className="title card-home-feature c-balck">Pro Active</h3>
                                <p className="paragraph card-home-feature c-balck-600">For an organisation.</p>
                            </div>
                            <div className="card-home-feature-pricing">
                                <h3 className="title-p  c-balck" style={{ fontSize: 17 }}> $ Calculating...</h3>
                                <p className="paragraph-p c-balck-600">Get all features and custom support team.</p>
                            </div>
                            <div className="card-home-feature-pricing-button">
                                <button className='btn-crt'>Get Started</button>
                            </div>
                            <div className="card-home-feature-pricing-bullet">
                                <ul>
                                    <li className='yes-p'>Upto 10 projects</li>
                                    <li className='yes-p'>More 4 language</li>
                                    <li className='yes-p'>1500 hosted string keys</li>
                                    <li className='yes-p'>Create team & Assign roles</li>
                                    <li className='yes-p'>Download report</li>
                                    <li className='yes-p'>Access on web platform</li>
                                    <li className='yes-p'>Access on Android platform</li>
                                    <li className='yes-p'>Access on IOS platform</li>
                                    <li className='yes-p'>Figma localization plugin</li>
                                    <li className='yes-p'>Custom features</li>
                                    <li className='yes-p'>Machine Translation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto" style={{ margin: 100 }}>
                        <p className="lead mb-4 c-balck-600">Make your own package based on what you organization requires.</p>
                        <div className="col-lg-8 mx-auto contact-pricing-section d-flex" >
                            <div className='w-50 py-5 h-100 px-5 contact-pricing-section-left' style={{ borderRight: "1px solid #00c483" }}>
                                <div>
                                    <p className='contact-pricing-section-p'>Need a custom solution?</p>
                                    <span className='contact-pricing-section-span'>
                                        Our Enterprise plans are designed to fit all your needs.
                                    </span>
                                    <div className='mt-4'>
                                        <Button variant='outlined' onClick={() => window.location.href = "mailto:info@vernacle.in"} className='contact-pricing-section-button'>Contact us for pricing <Icon icon={"material-symbols:arrow-forward-ios-rounded"} /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-50 pt-5 h-100 px-3 contact-pricing-section-right'>
                                <div>
                                    <ul className='list-styles-none'>
                                        <li className='yes-p-c'>Choose according to your needs</li>
                                        <li className='yes-p-c'>Custom integration</li>
                                        <li className='yes-p-c'>Feature addon</li>
                                        <li className='yes-p-c'>Volume discounts</li>
                                        <li className='yes-p-c'>Invoice based billing</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex flex-column my-5 justify-space-center flex-md-row gap-3 custom-pri">
                <div className="card home-feature pricing n-selected" style={{width : 400}}>
                    <div className="card-home-feature-type">
                        <h3 className="title card-home-feature c-balck">Custom</h3>
                        <p className="paragraph card-home-feature c-balck-600">For bigger enterprise.</p>
                    </div>
                    <div className="card-home-feature-pricing">
                        <h3 className="title-p  c-balck"> $ 0</h3>
                        <p className="paragraph-p c-balck-600">Not listed you plan? Decide your won plan.</p>
                    </div>
                    <div className="card-home-feature-pricing-button">
                        <button className='btn-crt'>Get Quotes</button>
                    </div>
                    <div className="card-home-feature-pricing-bullet">
                        <ul>
                            <li className='yes-p justify-space-between'>
                                <div style={{ width: "100%"}}>Upto 0 projects</div>
                                <Slider
                                aria-label="Temperature"
                                defaultValue={30}
                                getAriaValueText={valuetext}
                                color="secondary"
                            /></li>
                            <li className='yes-p justify-space-between'>
                                <div style={{ width: "100%"}}>Upto 0 language support</div> 
                            <Slider
                                aria-label="Temperature"
                                defaultValue={30}
                                getAriaValueText={valuetext}
                                color="secondary"
                            />
                            </li>
                            <li className='yes-p justify-space-between'>
                                <div style={{ width: "100%"}}>0 hosted string keys </div>
                                <Slider
                                aria-label="Temperature"
                                defaultValue={30}
                                getAriaValueText={valuetext}
                                color="secondary"
                                />
                            </li>
                            <li className='yes-p'>Create team & Assign roles</li>
                            <li className='yes-p'>Download report</li>
                            <li className='yes-p'>Access on web platform</li>
                            <li className='yes-p'>Access on Android platform</li>
                            <li className='check-p'>
                                <Checkbox
                                {...label}
                                defaultChecked
                                sx={{
                                color:"#00c483",
                                '&.Mui-checked': {
                                    color: "#00c483",
                                },
                                }}
                            />Access on IOS platform</li>
                            <li className='check-p'>
                                <Checkbox
                                {...label}
                                defaultChecked
                                sx={{
                                color:"#00c483",
                                '&.Mui-checked': {
                                    color: "#00c483",
                                },
                                }}
                            />Figma localization plugin</li>
                            <li className='check-p'>
                                <Checkbox
                                {...label}
                                defaultChecked
                                sx={{
                                color: "#00c483",
                                '&.Mui-checked': {
                                    color: "#00c483",
                                },
                                }}
                            />Custom features</li>
                            <li className='check-p'>
                                <Checkbox
                                {...label}
                                defaultChecked
                                sx={{
                                color: "#00c483",
                                '&.Mui-checked': {
                                    color: "#00c483",
                                },
                                }}
                            />Machine Translation</li>
                        </ul>
                    </div>
                </div>
            </div> */}
                </div>
            </div>
            <div className='px-4 m-auto get-demo-card'>
                <h2 style={{ fontSize: 45, textAlign: "center" }}>Try Vernacle on your #product 👋 </h2>
                <p className="paragraph card-home-feature-try c-white-600">Start a no-commitment 14-day free trail and enjoy all Vernacle features on your apps , websites. No one need to know your credit card 💳</p>

                <div className="container px-4 d-flex" style={{ flexWrap: "wrap", justifyContent: "center", gridGap: 10 }}>
                    <Button variant="contained" style={{ background: "#6343c8", padding: "10px 20px", borderRadius: 5, marginTop: 10, textTransform: "capitalize", fontWeight: 300 }}> Start your free trail </Button>
                    <Button variant="outlined" style={{ borderColor: "#00c483", color: "#00c483", padding: "10px 20px", borderRadius: 5, marginTop: 10, textTransform: "capitalize", fontWeight: 300 }} onClick={() => setIsDialogDisabled(false)} > <img src={Logo1} width={44} height={40} /> Book a demo with Us </Button>
                </div>
            </div>
        </div>
    )
}

export default Pricing