import React, { Fragment, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';

function FullLayout({children}) {
  
  return (
    <Fragment>
        <Navbar />
        <div>
            <Outlet />
        </div>
        <Footer />
    </Fragment>
  )
}

export default FullLayout