import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import { PopupButton } from '@typeform/embed-react';
import { createNewEntity } from '../Network/Integration';
import { validateEmail } from '../Network/Utils';

function Footer() {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = () => {
        if(validateEmail(email)){
            setIsLoading(true);
            createNewEntity({type: "news", email: email}, setEmail, setIsLoading, ()=>{})
        }else{
            setError("Invalid Email")
        }
       
    }
    return (
        <div className="container footer-fv">
            <footer className="pt-5">
                <div className="row d-flex align-items-center  footer-m justify-space-between">
                    <div className='col-3 mb-2 footer-m-info text-decoration-none position-relative'>
                        <div className='col-3 d-flex align-items-center mb-2 text-decoration-none position-relative'>
                            <img src={require('../Assets/Images/logo.png')} className="img-bg" width={100} alt="logo icon"/> <span className='icon-leading'>vernacle</span>
                        </div>
                        <div className="mb-3 p-4 c-white">
                        <form>
                            <h5>Subscribe to our newsletter</h5>
                            <p className='c-white-600'>Monthly digest of what's new and exciting from us.</p>
                            <div className=" flex-column flex-sm-row foot-form w-100 gap-3">
                                <label className="visually-hidden">Email address</label>
                                <input value={email} onChange={(e) => {setError("");setEmail(e.target.value)}} id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                                {error?.length > 0 && <p style={{ fontSize: 14, color: "red"}}>{error}</p>}
                                <button onClick={() => onSubmit()} className="btn btn-primary mt-2" type="button" disabled={isLoading || email?.length == 0}>{isLoading ? "Ok.." :"Subscribe" }</button>
                            </div>
                        </form>
                    </div>
                    </div>
                    <div className="col-3 mb-3 c-white sec-fot-m">
                        <h5>General</h5>
                        <ul className="nav d-block">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                            <li className="nav-item mb-2"><a href="/pricing" className="nav-link p-0 text-muted">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="/help&support" className="nav-link p-0 text-muted">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="/about" className="nav-link p-0 text-muted">About</a></li>
                        </ul>
                    </div>
                    <div className="col-3 mb-3 c-white sec-fot-m">
                        <h5>Integration</h5>
                        <ul className="nav d-block">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">React & Navtive</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Angular</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Vue.js</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Flutter</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Figma</a></li>
                        </ul>
                    </div>
                    <div className="col-3 mb-3 c-white sec-fot-m">
                        <h5>Need to read ?</h5>
                        <ul className="nav d-block">
                            <li className="nav-item mb-2"><a href="/privacy_ploicy" className="nav-link p-0 text-muted">Privacy Policy</a></li>
                            <li className="nav-item mb-2"><a href="/terms_of_use" className="nav-link p-0 text-muted">Terms OF Service</a></li>
                            <li className="nav-item mb-2"><a href="https://doc.vernacle.in/" target={"_blank"} className="nav-link p-0 text-muted">Documents</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted fade" >Give a star in ProductHunt</a></li>
                            {/* <li className="nav-item mb-2">
                                <PopupButton id="VECditsB" style={{ fontSize: 12 }} className="my-button">
                                    Give us a testomny
                                </PopupButton>
                            </li> */}
                        </ul>
                        
                                <PopupButton id="VECditsB" style={{ fontSize: 12 }} className="my-button">
                                    Give us a testomny
                                </PopupButton>
                        
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-1 my-1 border-top c-white">
                    <p>© 2022 vernacle, Inc. <a href='/copyrights'>All rights reserved.</a></p>
                    <ul className="list-unstyled d-flex">
                        <li className="ms-3">
                            <a className="link-dark" href="https://twitter.com/vernacle_lokal" target={"_blank"}>
                                <Icon icon="simple-icons:twitter" color="white" width="20" height="20" />
                            </a>
                        </li>
                        <li className="ms-3"><a className="link-dark" href="https://www.linkedin.com/company/91556037" target={"_blank"}>
                            <Icon icon="la:linkedin-in" color="white" width="20" height="20" />
                        </a>
                        </li>
                        <li className="ms-3"><a className="link-dark" href="https://discord.gg/8hGgDx8ZXn" target={"_blank"}>
                            <Icon icon="ic:outline-discord" color="white" width="20" height="20" />
                        </a>
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default Footer