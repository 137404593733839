import React from 'react'

function Terms() {
    return (
        <div>
            <div className="container px-4 py-2 my-2 text-center">
                <h1 className="display-5 fw-bold c-balck">Terms of Services <br /><span className='icon-title'>vernacle</span></h1>
            </div>
            <div style={{ color: "whitesmoke" }} className="container mx-auto px-4">
                <div className='my-3'>
                    <span>Effective Date: February 1st, 2023</span>
                </div>
                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>1. Introduction:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> These terms of use (the "Terms") govern your use of the Vernacle.in SaaS product (the "Service") in India. By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.</p>
                </div>

                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>2. Account Creation and Use:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> In order to use the Service, you must create an account and provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information and for any activity that occurs under your account. You agree to immediately notify Vernacle of any unauthorized use of your account.</p>
                </div>

                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>3. Service Availability:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> Vernacle makes no guarantee that the Service will be available at all times or at any specific times. Vernacle may modify, suspend, or discontinue the Service at any time without notice.</p>
                </div>

                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>4. Intellectual Property:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> The content of the Service, including but not limited to text, graphics, logos, images, software, and other materials, is protected by copyright and other intellectual property laws. You may not use any part of the Service for any commercial purpose without the express written permission of Vernacle.</p>
                </div>

                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>5. Third-Party Content:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> The Service may contain links to third-party websites and services. Vernacle is not responsible for the availability or accuracy of these third-party resources or the content, products, or services available from them. The inclusion of any link does not imply endorsement by Vernacle.</p>
                </div>
                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>6. Warranty Disclaimer:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY FOR INFORMATION, SERVICES, UNINTERRUPTED ACCESS, OR PRODUCTS PROVIDED THROUGH OR IN CONNECTION WITH THE SERVICE, INCLUDING WITHOUT LIMITATION THE SOFTWARE LICENSED TO YOU AND THE RESULTS OBTAINED THROUGH THE SERVICE. SPECIFICALLY, VERNACLE DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING WITHOUT LIMITATION: 1) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, USEFULNESS, OR CONTENT OF INFORMATION, AND 2) ANY WARRANTIES OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
                </div>
                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>7. Limitation of Liability:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> IN NO EVENT WILL VERNACLE BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER RELATED TO OR ARISING FROM THE SERVICE OR ANY USE OF THE SERVICE, OR OF ANY SITE OR RESOURCE LINKED TO, REFERENCED, OR ACCESSED THROUGH THE SERVICE, OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES, INCLUDING WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF VERNACLE IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                </div>
                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>8. Modification of Terms:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> Vernacle may modify these Terms at any time without notice. Your continued use of the Service after any such modification constitutes your agreement to be bound by the modified Terms.</p>
                </div>
                <div className='py-4 col-9'>
                    <b style={{ fontSize: 18, }}>9.Governing Law:</b>
                    <p style={{ fontSize: 18, lineHeight: 2 }}> These Terms are governed by the laws of India</p>
                </div>
            </div>
        </div>
    )
}

export default Terms