import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react'

function MetaDecorder({ title, description, imageUrl }) {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta name="title" property="og:title" content={title} />
                <meta property="og:type" content="website" />
                <meta name="image" property="og:image" content="https://iili.io/HCyR1kX.png" />
                <meta name="description" property="og:description" content={description} />
                <meta name="author" content="Vernacle" />

                <meta property="og:title" content={title} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://iili.io/HCyR1kX.png" />
                <meta
                    property="og:url"
                    content={window.location.pathname + window.location.search}
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@vernacle_lokal" />
                <meta name="twitter:image" content="https://iili.io/HCyR1kX.png" />
                <meta name="twitter:description" content={description} />
            </Helmet>
        </HelmetProvider>
    )
}

export default MetaDecorder;