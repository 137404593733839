import { Icon } from '@iconify/react'
import { PopupButton } from '@typeform/embed-react'
import React from 'react'
import MetaDecorator from '../components/MetaDecorder'
import Logo1 from '../Assets/Images/logo.png';
import Group2 from '../Assets/Images/Group.png';
import Group1 from '../Assets/Images/Group1.png';

function About() {
    return (
        <div className="main-dashboard-wrapper my-5">
            <MetaDecorator title={"About Vernacle Localization - Vernacle translation"} description={"We empower leaders builders to create a localisation experiences for their customers, co-workers, and partners very easy"} imageUrl={Logo1}/>
            <div className='d-flex'>
            <div className="container pl-4 py-2 my-2">
                <div className="about-hero-content">
                    <h1 style={{ transform: "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="display-1 about-hero">
                            We’re on a mission to make your 
                        <span className="text-color-accent">
                            translation easy
                        </span>
                    </h1>
                    <p style={{ transform: "translate3d(0px, 0px, 0px), scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="paragraph-large about-hero">
                        <span>We empower leaders &amp; builders to create a localisation experiences
                            <br />for their customers, co-workers, and partners very easy
                        </span>
                    </p>
                </div>
                <div className='d-flex py-2' style={{flexWrap: "wrap"}}>
                    <div className="aboutus-section-left">
                        <h2 className='about-hero-h2'>About Us</h2>
                        <p className='about-hero-p'>
                            Vernacle is a tool to solve localization bottlenecks on multi-lingual projects. 
                            But soon we noticed that the companies are using much more complex method to manage it, getting each time copy & paste, replacing files and it grew into a very tough. We’ve helped teams solve their software localization problems and enter into markets by making the best possible translation management software.
                            The team behind the product is continually learning and improving, and it is our mission to help you solve your localization challenges efficiently and to remove obstacles to your global success.
                        </p>
                        <p className='about-hero-p'>
                            We will onbaording much more Integrations for apps which help developer or designer. We will be hearing you issue and feature request to make it new, 
                        </p>
                        <p className='about-hero-p'>
                            We are a bunch of developer who have enough experience to understand your problems and build a SAAS to manage localization challenges efficiently. Founded in 2022 we haven't raised fund, we are bootstarped, now we are more focusing more on the qaultiy and problemtic statement and increase our team memeber.   
                        </p>
                    </div>
                    <img src={Group2} className="img-rotate"/>
                </div>
                {/* <div className='d-flex py-2'>
                    <div >
                        <h2 className='about-hero-h2'>Who we are ...</h2>
                        <p className='about-hero-p'>
                            Vernacle is a tool to solve localization bottlenecks on multi-lingual projects. 
                            But soon we noticed that the companies are using much more complex method to manage it, getting each time copy & paste, replacing files and it grew into a very tough. We’ve helped teams solve their software localization problems and enter into markets by making the best possible translation management software.
                            The team behind the product is continually learning and improving, and it is our mission to help you solve your localization challenges efficiently and to remove obstacles to your global success.
                        </p>
                        <p className='about-hero-p'>
                        We will onbaording much more Integrations for apps which help developer or designer. We will be hearing you issue and feature request to make it new, 
                        </p>
                        <p className='about-hero-p'>
                        We are a bunch of developer who have enough experience to understand your problems and build a SAAS to manage localization challenges efficiently. Founded in 2022 we haven't raised fund, we are bootstarped, now we are more focusing more on the qaultiy and problemtic statement and increase our team memeber.   
                        </p>
                    </div>
                    
                </div> */} 
                    
                 <div>
                    <h2 className='about-hero-h2'>Who we are...</h2>
                        <p className='about-hero-p'>
                            We are thinker and inovators of new era, we had a good back so don't worry you can foucs on paying us we will be focuisng on our quality and product.  
                            We came from strong background of technology so that you can keep count on us.
                        </p>
                        <p className='about-hero-p'>
                            Mindfulness, empathy and caring, combined with a passion for craftsmanship, have been hallmarks of how we approach our work since we started this company.
                        </p>
                        {/* <img src={Group1} width={600} className="img-rotate"/> */}
                        
                    </div>
            </div>
            </div>
            <div className="container-default my-5 home-features w-container">
                <div className='my-form-tf justify-al-c'>
                    <div className='img-abs'>
                    <Icon icon="openmoji:comment" style={{fontSize: 100}} />
                    <Icon icon="openmoji:thinking-face" style={{fontSize: 100}}/><br/>
                    <Icon icon="openmoji:comment" style={{fontSize: 100}} />
                    <Icon icon="openmoji:thinking-face" style={{fontSize: 100}}/><br/>
                    <Icon icon="openmoji:comment" style={{fontSize: 100}} />
                    <Icon icon="openmoji:thinking-face" style={{fontSize: 100}}/>
                    </div>
                    <PopupButton id="VECditsB" style={{ fontSize: 20 }} className="my-button">
                        Click here to give a testimony
                    </PopupButton>
                </div>
            </div>
        </div>
    )
}

export default About