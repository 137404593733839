import React, { useState } from 'react'
import MetaDecorator from '../components/MetaDecorder';
import ImgCo from '../Assets/Images/contact-us.png';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import SwipeableIssues from '../components/SwipableDrawer';

function ContactUs() {
    const [openTicket, setOpenTicket] = useState(false);

    const faq = [{
        id: 1,
        question: 'How I can access my portal ?',
        answer: 'First of all you need to have an account with us if you don`t have one please singup with our portal with your email <Link to={"https://console.vernacle.in"} target="_blank">https://console.vernacle.in</Link> . Then try to login again on the same portal.'
    },
    {
        id: 2,
        question: 'Getting blank portal after logged in ?',
        answer: 'Have you completed your signup flow ? or you can try to login again. For further you can reach out to us anytime'
    },
    {
        id: 3,
        question: 'How do I remove my account?',
        answer: 'As a logged in user, go to <strong>Setting</strong> page under Gerneral setting on left sidebar. On a given page there is Danger zone card where you can remove your account permanently. Be aware that all data linked to your account and projects you own, will not be able to restore after that step.'
    },{
        id: 4,
        question: 'How can I upgrade my plan?',
        answer: 'Under Setting sidebar, you can find to <strong>Billing Setting</strong> page. There you can see your current Account plan and once your initial Trial plan expires you will see<!-- --> <strong>Upgrade</strong> button.<br>When changing plans, we will prorate the subscription costs.'
    }];

    return (
        <div className="main-dashboard-wrapper my-5">
            <MetaDecorator title={"Vernacle Contact and support"} description={"We are here for you. Get help & Support anytime only one ping ahead."} />
            {openTicket && <SwipeableIssues setOpenTicket={setOpenTicket} />}
            <div className="container px-4 py-2 text-center">
                <img src={ImgCo} width={400} className="mb-2 img-contact" />
                <h1 className="display-5 fw-bold c-balck">Are you facing any problem with <br /><span className='icon-title'>vernacle</span> ?</h1>
                <p className="lead c-white-600 col-lg-8 m-auto" style={{ textAlign: 'center' }}>If you need instant support then use slack chat option to reach us quickly. our support will reply as soon as possible after you send us a message.</p>
            </div>
            <div className="container px-4 py-2 my-2 d-flex"  style={{flexWrap: "wrap", justifyContent: "center", gridGap: 10}}>
                <Button variant='contained' style={{ background: "#6343c8", padding: "10px 20px", borderRadius: 50,  marginTop: 10 }} onClick={() => window.open("https://join.slack.com/t/vernacle/shared_invite/zt-1psoa8ybk-m1Rk4P_gPOnArKsYSeNCOw", "_blank")}><Icon icon={"logos:slack-icon"} className="mx-2" width={24} /> Slack chat </Button>
                <Button variant='contained' style={{ background: "#00c483", padding: "10px 20px", borderRadius: 50, marginTop: 10 }}  onClick={() => {
                    setOpenTicket(!openTicket)}}> <Icon icon={"mdi:tag-multiple-outline"} className="mx-2" width={24} /> Raise an issue </Button>
            </div>

            <div className="container px-4 py-2 my-2 text-center">
                <b className="c-white-600 col-lg-8 m-auto" style={{ textAlign: 'center' }}>Or you can contact at</b>
                <div className='my-4 d-flex' style={{flexWrap: "wrap", justifyContent: "center"}}>
                    <b className='p-2 mx-4 my-2 bg-contact'><span className="c-white-600">E-mail:</span> <Link onClick={() => window.location.href = "mailto:support@vernacle.in"}>support@vernacle.in</Link></b>
                    <b className='p-2 my-2 bg-contact'><span className="c-white-600">Phone:</span> <Link>7905XXXXX</Link></b>
                </div>
            </div>

            <div className="container px-4 py-4 text-center" style={{ marginTop: 80 }}>
                <h3 className="c-white col-lg-8 m-auto" style={{ textAlign: 'center' }}>May be we have already the solution </h3>
                <div className='my-4'>
                    <Chip label="Accounts" variant="outlined" className='m-1' color='primary' />
                    <Chip label="Get Started" variant="outlined" className='m-1' color='primary' />
                    <Chip label="Integrations" variant="outlined" className='m-1' color='primary' />
                    <Chip label="Payments" variant="outlined" className='m-1' color='primary' />
                    <Chip label="Invites" variant="outlined" className='m-1' color='primary' />
                </div>
                <div className="col-lg-9 m-auto">
                    {faq?.map((d, id) => 
                        <Accordion key={d.id} style={{ 
                            background: "rgb(221 222 255)",
                            borderRadius: "20px",
                            padding: "20px",
                            marginTop: 20
                        }}
                        >
                            <AccordionSummary
                                expandIcon={<Icon icon={"line-md:arrow-open-down"} width={24}/>}
                                aria-controls={`panel${id}a-content`}
                                id={`panel${id}a-header`}
                            >
                                <b style={{fontSize: 20}}>
                                    Q. {d.question}
                                </b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p style={{ textAlign: "left", fontSize: 20}} dangerouslySetInnerHTML={{__html: d.answer}}>
                                    
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ContactUs