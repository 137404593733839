import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Icon } from '@iconify/react';
import { Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { createNewTicket } from '../Network/Integration';
import { validateEmail } from '../Network/Utils';

export default function SwipeableIssues({setOpenTicket}) {
    const [state, setState] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [isUser, setIsUser] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [type, setType] = React.useState('improvement');
    const [raised, setRaised] = React.useState('website');
    const [level, setLevel] = React.useState('blue');
    const [error, setError] = React.useState('');
    const [errorstatus, setErrorStatus] = React.useState({success: false, message: ''});
    const [errorInput, setErrorInput] = React.useState("")
    const toggleDrawer = (open) => (event) => {
        console.log("sds", open)
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState(open);
        setOpenTicket(false);
    };

    const saveData = () => {
        if(email == "" || title == "")
          setError("Email and title is required.");
        else{
            if(validateEmail(email)){
                const payload = {
                    email: email,
                    title: title,
                    description: description,
                    level: level,
                    raised: raised,
                    isuser: isUser,
                    type: "issue",
                    type_t: type,
                }
                createNewTicket(payload, clearFeild, setLoading, setOpenTicket, setErrorStatus);
            }else{
                setErrorInput("Invalid Email")
            }
        }
    }

    const clearFeild = () => {
        setEmail('')
        setDescription('')
        setTitle('')
        setType('improvement')
        setIsUser('')
        setError('')
        setRaised('website')
        setLevel('blue')
    }

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={state}
            // onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            <Box
                sx={{ width: 350, height: "100%", overflow: "hidden"}}
                role="presentation"
                className="background-box-drawer"
            //   onClick={() => onClose(false)}
            //   onKeyDown={() => onClose(false)}
            >
                <div>
                    <div>
                        <List>
                            <div className="px-2 py-3">
                                <div className="d-flex justify-content-sm-between flex-sb px-3">
                                    <h2>Raise a new ticket</h2>
                                    <Icon icon="material-symbols:close-rounded" width={30} className='icon-close-drawer' onClick={() => setOpenTicket(false)} />
                                </div>
                            </div>
                        </List>
                    </div>
                    <Divider />
                </div>
                <div className='px-5 pb-5' style={{height: "90vh", overflowY: "scroll"}}>
                   {errorstatus?.message?.length > 0 ? <div className='mt-0 ul-custom-div' style={{ 
                        justifyContent: "center",
                        height: "100%",
                        alignItems: "center"}}>
                            <h2>{errorstatus?.success  ? "Success": "Failed"}</h2>
                            <b style={{textAlign: "center"}}>{errorstatus.message}</b>
                   </div>
                   :
                   <div className='mt-0 ul-custom-div'>
                   <div className='input-label'>
                        <label>Your e-mail :</label>
                        <input placeholder='Give your email' value={email} onChange={(e) => {
                            setErrorInput(""); 
                            setError("");
                            setEmail(e.target.value);
                        }} required/>
                        {errorInput?.length > 0 && <p style={{ fontSize: 14, color: "red"}}>{errorInput}</p>}
                    </div>
                    <div className='input-label'>
                        <label>Issue title :</label>
                        <input placeholder='Give a nice title' value={title} onChange={(e) => {
                            setTitle(e.target.value);
                            setError("");
                        }} required/>
                        <span className='span-count'>count: {title.length}/200</span>
                    </div>
                    <div className='input-label'>
                        <label>Describe issue :</label>
                        <textarea placeholder='Describe your ticket reason' value={description} onChange={(e) => setDescription(e.target.value)}/>
                        <span className='span-count'>count: {description.length}/1000</span>
                    </div>
                    <div className='input-label'>
                        <label>Raise against :</label>
                        <select value={raised} onChange={(e) => setRaised(e.target.value)}>
                            <option value={"webiste"}>Website</option>
                            <option value={"console"}>Console</option>
                            <option value={"docs"}>Documentation</option>
                        </select>
                    </div>
                    <div className='input-label'>
                        <label>Type of ticket :</label>
                        <select value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={"bug"}>Issue /Bug</option>
                            <option value={"feature"}>New Feature</option>
                            <option value={"improvement"}>Improvement</option>
                            <option value={"other"}>Reaching out</option>
                        </select>
                    </div>
                    <div className='input-label'>
                        <label>Level :</label>
                        <Select value={level} size="small" sx={{width: "90%"}} onChange={(e) => setLevel(e.target.value)}>
                            <MenuItem value={"red"} > <Icon icon={"material-symbols:line-start-square-rounded"} width={30} color="red"/> Red</MenuItem>
                            <MenuItem value={"blue"} ><Icon icon={"material-symbols:line-start-square-rounded"} width={30} color="blue"/> Blue</MenuItem>
                            <MenuItem value={"yello"}> <Icon icon={"material-symbols:line-start-square-rounded"} width={30} color="orange"/> yellow</MenuItem>
                            <MenuItem value={"green"}> <Icon icon={"material-symbols:line-start-square-rounded"} width={30} color="green"/>Green</MenuItem>
                        </Select>
                        
                    </div>
                    <div>
                        <FormControlLabel className='form-custm-css' control={<Checkbox value={isUser} onChange={(e) => setIsUser(e.target.checked)}/>} label={<span>Do you have an account at <Link to={"https://console.vernacle.in"} target="_blank">vernacle</Link>?</span>} />
                    </div>
                    {error?.length > 0 &&  <span style={{color: "red"}}>{error}</span>}
                    <div className='mt-4 m-auto'>
                        <Button disabled={loading} variant='outlined' onClick={() => saveData()}>{loading ? "Doing it ..." : "I am done!"}</Button>
                    </div>
                   </div>
                   }
                </div>
            </Box>
        </SwipeableDrawer>
    );
}