import React from 'react'
import Logo1 from '../Assets/Images/logo.png';
import supabase from '../Network/DbConfig';

function Navbar() {
  const pathname = window.location?.pathname.split("/")[1];
  console.log("supabase", supabase)
  return (
    <div className='container_navbarwrap'>
    <header className='d-flex flex-wrap align-items-center justify-content-md-between py-3 mb-4'>
      <a href='/'>
      <div className='d-flex align-items-center header-list-menu col-md-3 mb-2 mb-md-0 text-dark text-decoration-none position-relative'>
        <img src={Logo1} className="img-bg" alt="mage background" width={100} /> <span className='icon-heading'>vernacle</span>
      </div>
      </a>
      <ul className="nav col-md-auto mb-2 mb-md-0">
        <li style={{borderBottom : pathname == "" && "3px solid #00c483"}}><a href="/" className="nav-link px-2 link-light">Home</a></li>
        <li style={{borderBottom : pathname == "bundle" && "3px solid #00c483"}}><a href="/bundle" className="nav-link px-2 link-light">Bundle</a></li>
        <li style={{borderBottom : pathname == "pricing" && "3px solid #00c483"}}><a href="/pricing" className="nav-link px-2 link-light">Pricing</a></li>
        {/* <li style={{borderBottom : pathname == "docs" && "3px solid #00c483"}}><a href="https://docs.vernacle.in//docs/prologue/introduction/" target={"_blank"} className="nav-link px-2 link-light">Docs</a></li> */}
        <li style={{borderBottom : pathname == "about" && "3px solid #00c483"}}><a href="/about" className="nav-link px-2 link-light">About</a></li>
        <li style={{borderBottom : pathname == "help&support" && "3px solid #00c483"}}><a href="/help&support" className="nav-link px-2 link-light">Contact</a></li>
      </ul>
      <div className="col-md-auto text-end">
        <div className='custom-border-color'>
          <button type="button" className="btn custom-scale">Try for free</button>
        </div>
      </div>
    </header>
    </div>
  )
}

export default Navbar