// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";

// ReactDOM.hydrate(
//   <React.StrictMode>
//       <BrowserRouter>
//           <App />
//       </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

import React from 'react';
import './index.css';
import PublicRouter from './Router';
import { hydrate, render } from "react-dom";
import { createRoot } from 'react-dom/client';

const App = () => {
  return <React.StrictMode>
    <PublicRouter />
  </React.StrictMode>
}

const rootElement = document.getElementById("root");
// const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
// root.render(<App/>);
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

