import { Icon } from '@iconify/react';
import { Button } from '@mui/material';
import React from 'react'
import '../Assets/Style/FNP.css';
import MetaDecorder from '../components/MetaDecorder';

function PNF() {
  return (
    <div className="errorpage">
        <MetaDecorder title={"404 Page mot found -Vernacle Localization "} description={"Translate your code with vernacle using no code "}/>
        {/* <div className='m-5 errorpage'>
            <div>
                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/Mirror.png" alt="mage 404" />
            </div>
            <div className='errorpage-main'>
                <h1 className="errorpage-h1">Sorry!</h1>
                <p className="errorpage-p">
                    Either you aren't cool enough to visit this page or it doesn't exist <em>. . . like your social life.</em>
                </p>
                <button className="errorpage-button" onClick={() => window.location.href = "/"}>You can go now!</button>
            </div>
        </div> */}
        <div className='m-1 '>
           <div>
             <h1 className='errorpage-main'>404</h1>
           </div>
           <div>
             <div style={{float: "right", width: 600, textAlign: "center"}}>
                <h2>Oops! There's NOTHING here....</h2>
                <p>Oops... The link you clicked may be broken or the page may have been removed. We're sorry.</p>
                <Button className='errorpage-button' style={{color: "#00c483"}}>
                    <Icon icon={"heroicons:arrow-long-left-solid"} width={80} height={80} color={"#00c483"} />
                     Back to home
                </Button>
             </div>
           </div>
        </div>
    </div>
  )
}

export default PNF