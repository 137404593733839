import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { PopupButton } from '@typeform/embed-react';
import AOS from 'aos';
import MetaDecorator from '../components/MetaDecorder';
import Neo from '../Assets/Images/3dart.png';
import Neo2 from '../Assets/Images/pngwing.png';
import Logo1 from '../Assets/Images/logo.png';
import LogoGIf2 from '../Assets/Images/ezgif.gif';
import vdoPoster from '../Assets/Images/poster.png';
import vdo from '../Assets/Images/myVod.mp4';
import '../App.css';
import {PopUpDemo} from '../components/PopupDemo';
import { Button } from '@mui/material';

const Home = () => {
    const [isDialogDisabled, setIsDialogDisabled] = useState(true);

    useEffect(() => { 
      setTimeout(() => {
        setIsDialogDisabled(false);
      }, 3000)
    }, []);
    
    useEffect(() => {
      AOS.init({ duration: 1000});
    }, []);
    
  return (
    <div className="main-dashboard-wrapper my-5">
      <MetaDecorator title={"Vernacle localization - Vernacle translation"} description={"Translate your code with vernacle using no code . Quickly translate and manage your translation over browser apps, android app and IOS app, the world’s most popular localization toolkit, featuring colabaration with your team and translator, and offering powerful figma localization plugin - figma translation plugin"} imageUrl={Logo1}/>
      {!isDialogDisabled && <PopUpDemo id="dailog-cl" setIsDialogDisabled={setIsDialogDisabled}/>}
      <div className="row p-1 pb-0 pe-lg-0 pt-lg-5 align-items-centerd ctx-p rounded-3">
        <div className="col-lg-11 p-0 m-auto">
          <p className="display-4 fw-bold lh-1 c-white col-lg-9 m-auto" style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center"  }} >Translate your code with <span className='icon-title'>vernacle, </span> with no code</p>
          <p className="lead c-white-600 col-lg-8 m-auto" style={{textAlign: 'center'}}>Quickly translate and manage your translations over browser, android and ios apps, using world’s most popular localization toolkit, featuring collaboration with your team and translator, and offering powerful figma localization plugin - figma translation plugin.</p>
          <div className="d-grid gap-2 justify-content-md-start mb-lg-3 mt-20">
            <div data-aos="fade-right">
              <span className='circ1'></span>
              <span className='circ2'></span>
            </div>
            <br/>
            <div data-aos="fade-left">
              <span className='circ3'></span>
            </div>
            <br/>
            <div data-aos="fade-right">
              <span className='circ4'></span>
            </div>
          </div>
        </div>
      </div>
      <div className='display-5'>
          <div className="col-lg-9 offset-lg-1 border-r-w">
            <img src={Neo2} alt="" width={300} style ={{ zoom: 1.3}} data-aos="fade-left" className='img-pos-nab'/>
            <img src={LogoGIf2} alt="" data-aos="fade-left" className='img-pos-3'/>
            {/* <img src={Neo} alt="" width={300} style = {{ zoom: 1.3, zIndex: -1, opacity: .5}} data-aos="fade-left" className='img-pos-3ab'/> */}
        </div>
      </div>
     
      <div className="container px-4 py-5 my-5 text-center">
        <h1 className="display-5 fw-bold c-white" style={{ position: "relative", zIndex: 2 }}>Platforms & Integrations</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4 c-white-600">Are you worried about translating across all your solutions ? Walk with us to integrate with no code</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <h4 className='icon-title'>NO CODE INTEGRATIONS REQUIRED</h4>
          </div>
        </div>
        <div className="container-default my-5 home-features w-container">
          <div className="d-flex flex-column my-5 home-feature-m flex-md-row gap-3" >
            <div className="card home-feature" data-aos="fade-up">
              <img src="https://api.iconify.design/logos/figma.svg?width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Figma</h3>
                <p className="paragraph card-home-feature c-white-600">Enable figma plugin and create design based on localization on a single click.</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
            <div className="card home-feature" data-aos="fade-down">
              <img src="https://api.iconify.design/el/website.svg?color=%236343c8&width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Website</h3>
                <p className="paragraph card-home-feature c-white-600">Enable localization for your web apps and get your customer more close.</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
            <div className="card home-feature" data-aos="fade-up">
              <img src="https://api.iconify.design/logos/android-vertical.svg?width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Android</h3>
                <p className="paragraph card-home-feature c-white-600">Reach out to us for end to end mobile support enabling localization across android devices</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
            <div className="card home-feature" data-aos="fade-down">
              <img src="https://api.iconify.design/fa-brands/app-store-ios.svg?color=white&width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">iOS</h3>
                <p className="paragraph card-home-feature c-white-600">Seamless integration with ios apps for the best of localization</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
          </div>
          <div className="d-flex flex-column my-5 home-feature-m flex-md-row gap-3" >
            <div className="card home-feature" data-aos="fade-up">
              <img src="https://api.iconify.design/logos/slack-icon.svg?width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Slack</h3>
                <p className="paragraph card-home-feature c-white-600">Enable slack integration to receive all your updates on your slack channel.</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
            <div className="card home-feature" data-aos="fade-down">
              <img src="https://api.iconify.design/logos/aws-s3.svg?width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Amazon S3</h3>
                <p className="paragraph card-home-feature c-white-600">Enable Amazon S3 to upload all your localization file.</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
            <div className="card home-feature" data-aos="fade-up">
              <img src="https://api.iconify.design/fa6-brands/jira.svg?color=blue&width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Jira</h3>
                <p className="paragraph card-home-feature c-white-600">Create jira issues and track those issue using our jira integrations</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
            <div className="card home-feature" data-aos="fade-down">
              <img src="https://api.iconify.design/flat-color-icons/command-line.svg?width=40&height=40" loading="eager" alt="logos" className="image card-home-feature c-white" />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">CLI</h3>
                <p className="paragraph card-home-feature c-white-600">Seamless integration with command line tools to pull or push the localization</p>
              </div>
              <button href="" className="button-secondary w-button">Learn More</button>
            </div>
          </div>
          
        </div>
      </div>
      <div className="container px-4 py-5 my-5 text-center">
        <h1 className="display-5 fw-bold c-white">What we enable for you...</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4 c-white-600">Are you worried about translating across all your solutions ? Know what we are providing you.</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <h4 className='icon-title'>Implement. Translate. Deploy.</h4>
          </div>
        </div>
        <div className="container-default my-5 home-features w-container">
          <div className="d-flex flex-column my-5 flex-md-row home-feature-m gap-3">
            <div className="col-3 card home-feature" data-aos="fade-up">
              <Icon className="c-white-600 my-3" icon="fluent-mdl2:task-manager" width={100}/>
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Manage translation</h3>
                <p className="paragraph card-home-feature c-white-600">Manage all language translation in one place. Handle different platforms..</p>
              </div>
              {/* <a href="" className="button-secondary w-button">Learn More</a> */}
            </div>
            <div className="col-3 card home-feature" data-aos="fade-up">
              <Icon className="c-white-600 my-3" icon="carbon:app-connectivity" width={100} />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Collaborate teams</h3>
                <p className="paragraph card-home-feature c-white-600">Communicate, give feedback, and update tasks on portal. Invite different professionals at one place.</p>
              </div>
              {/* <a href="" className="button-secondary w-button">Learn More</a> */}
            </div>
            <div className="col-3 card home-feature" data-aos="fade-up">
              <Icon className="c-white-600 my-3" icon="clarity:file-settings-solid-alerted" width={100}  />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Process Automate</h3>
                <p className="paragraph card-home-feature c-white-600">Automate your work, make developer and designer work easy</p>
              </div>
              {/* <a href="" className="button-secondary w-button">Learn More</a> */}
            </div>
            <div className="col-3 card home-feature" data-aos="fade-up">
              <Icon className="c-white-600 my-3" icon="fluent-mdl2:release-definition" width={100}/>
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Release faster</h3>
                <p className="paragraph card-home-feature c-white-600">We will help you to build your code faster and smother. The faster you make faster you release. </p>
              </div>
              {/* <a href="" className="button-secondary w-button">Learn More</a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 py-5 my-5 text-center whatwe-do-m">
        <h1 className="display-5 fw-bold c-white">...watch what we do here</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4 c-white-600">Are you worried about translating across all your solutions ? Know what we are providing you</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            {/* <h4 className='icon-title'>Implement. Translate. Deploy.</h4> */}
          </div>
        </div>
        <div className="container-default my-5 home-features w-container">
          <div className="d-flex justify-content-sm-center flex-column my-5 flex-md-row gap-3 position-relative">
            <div className='vdoframe mt-4'>
              <p style={{ fontSize: 2}}>Vernacle localization Video</p>
              <video width="900" controls poster={vdoPoster} src={vdo} type="video/mp4" height="500" allowFullScreen >
              </video>
             
            </div>
            <div data-aos="fade-left" className="grid-style-recat2">
            <div>
              <span className='circ1'></span>
              <span className='circ2'></span>
            </div>
            <div>
              <span className='circ3'></span>
            </div>
            <div>
              <span className='circ4'></span>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div className='py-5 my-5 text-center'>
         <div className='px-4 m-auto get-demo-card'>
            <h2 style={{ fontSize: 45}}>Try Vernacle on your #product 👋 </h2>
            <p className="paragraph card-home-feature-try c-white-600">Start a no-commitment 14-day free trail and enjoy all Vernacle features on your apps , websites. No one need to know your credit card 💳</p>
            
            <div className="container px-4 d-flex"  style={{flexWrap: "wrap", justifyContent: "center", gridGap: 10}}>
                <Button variant="contained" style={{ background: "#6343c8", padding: "10px 20px", borderRadius: 5,  marginTop: 10, textTransform: "capitalize", fontWeight: 300 }}> Start your free trail </Button>
                <Button variant="outlined" onClick={() => setIsDialogDisabled(false)} style={{ borderColor: "#00c483", color: "#00c483", padding: "10px 20px", borderRadius: 5, marginTop: 10, textTransform: "capitalize", fontWeight: 300  }} > <img src={Logo1} width={44} height={40}/> Book a demo with Us </Button>
            </div>
         </div>
      </div>
      <div className="container px-4 py-5 my-5 text-center">
        <h1 className="display-5 fw-bold c-white">Who will have these benefits...</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4 c-white-600">See who can access this portal and localization toolkit.</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
          </div>
        </div>
        <div className="container-default my-5 home-features w-container">
          <div className="row d-flex flex-column flex-wrap my-5 flex-md-row gap-5">
            <div className="col-6 card home-feature-l" data-aos="fade-right" >
              <Icon className="g-color my-3" icon="fluent:checkbox-person-20-filled" width={50}/>
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Developers / Designers</h3>
                <p className="paragraph card-home-feature c-white-600">
                  Are you doing manual copy and paste? Automate and improve your localization process using our services and seamless integration with your code base.
                </p>
              </div>
            </div>
            <div className="col-5 card home-feature-l" data-aos="fade-left" >
              <Icon className="g-color my-3" icon="fluent:checkbox-person-20-filled" width={50} />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Localization Managers</h3>
                <p className="paragraph card-home-feature c-white-600">
                  Localization manager can join our portal to see all configured languages and translations according to the current project very efficiently and will also be able to see the change history and download reports.
                </p>
              </div>
            </div>
            <div className="col-5 card home-feature-l" data-aos="fade-right" >
              <Icon className="g-color my-3" icon="fluent:checkbox-person-20-filled" width={50}  />
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Product Managers</h3>
                <p className="paragraph card-home-feature c-white-600">
                  Prepare new features for localization and update existing ones. Follow localization progress and individual activities of your team members in one place.  
                </p>
              </div>
            </div>
            <div className="col-6 card home-feature-l" data-aos="fade-left" >
              <Icon className="g-color my-3" icon="fluent:checkbox-person-20-filled" width={50}/>
              <div className="card-home-feature-content">
                <h3 className="title card-home-feature c-white">Translators</h3>
                <p className="paragraph card-home-feature c-white-600">
                  Test the translation in different environments, without opening apps. Solve expansion problems before they even get raised by looking at your localization portal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 py-5 my-5 text-center">
        <h1 className="display-5 fw-bold c-white">Leave a testimony...</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4 c-white-600">If you like our product or idea please write a testimony. </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
          </div>
        </div>
        <div className="container-default my-5 home-features w-container">
           <div className='my-form-tf'>
            <div className='img-abs'>
              <Icon icon="openmoji:comment" data-aos="zoom-out-right"  style={{fontSize: 100}} />
              <Icon icon="openmoji:thinking-face" data-aos="zoom-out-right" style={{fontSize: 100}}/><br/>
              <Icon icon="openmoji:comment" data-aos="zoom-out-left" style={{fontSize: 100}} />
              <Icon icon="openmoji:thinking-face" data-aos="zoom-out-left" style={{fontSize: 100}}/><br/>
              <Icon icon="openmoji:comment" data-aos="zoom-out-left" style={{fontSize: 100}} />
              <Icon icon="openmoji:thinking-face" data-aos="zoom-out-right" style={{fontSize: 100}}/>
            </div>
            <PopupButton id="VECditsB" style={{ fontSize: 20 }} className="my-button">
              Click here to give a testimony
            </PopupButton>
           </div>
        </div>
      </div>
    </div>
  )
}

export default Home