import React from 'react'

function Copyrights() {
    return (
        <div>
            <div className="container px-4 py-2 my-2 text-center">
                <h1 className="display-5 fw-bold c-balck">Copyrights reserved for <br/><span className='icon-title'>vernacle</span></h1>
            </div>
            <div style={{ color: "whitesmoke"}} className="container mx-auto px-4">
            <div className='my-3'>
                <span>Effective Date: February 1st, 2023</span>
            </div>
            <div className='my-3'>
                <b>Copyright © 2023 Vernacle, Inc. All rights reserved.</b>
            </div>

            <div className='py-4 col-9'>
                <p style={{fontSize: 18, lineHeight: 2, letterSpacing: 0.87}}>
                    The content of the Vernacle.in SaaS product, including but not limited to text, graphics, logos, images, software, and other materials, is protected by copyright under both United States and foreign laws. Unauthorized use, reproduction, or distribution of any part of the SaaS product may result in civil and criminal penalties.

                    You may access, view, download, and print content from the SaaS product solely for your personal, non-commercial use. You may not modify the content or use it for any commercial purpose without the express written permission of Vernacle.

                    Vernacle trademarks and logos used in connection with the SaaS product are trademarks of Vernacle, Inc. and may not be used without the express written permission of Vernacle.

                    If you believe that your work has been copied in a way that constitutes copyright infringement, please contact us at copyright@vernacle.in.

                    This copyright notice was last updated on February 1st, 2023.
                </p>
            </div>
        </div>
        </div>
    )
}

export default Copyrights