// import {Client, Account, Databases} from 'appwrite';
// import {v4 as uuidv4} from 'uuid';

import supabase from "./DbConfig"


// const client = new Client();

// client
//     .setEndpoint(process.env.REACT_APP_BASEURL)
//     .setProject(process.env.REACT_APP_API_KEY);


// export const account = new Account(client)

// //
// export const database = new Databases(client, process.env.REACT_APP_DB_ID)

export const createNewEntity = async(payload, setEmail, setLoading, setError) => {
    const { data, error } = await supabase
      .from('demo-feedback')
      .insert(payload)
    if (error) {
        console.log(error);
        setEmail('');
        setTimeout(() => {
            setLoading(false);
            setError({success: false, message: 'Oops! We missed, can you try again.'});
            // alert("Oops! We missed it");
        },1000);
        return
    }
    // if (data) {
        setEmail('');
        setTimeout(() => {
            setLoading(false);
            setError({success: true, message: 'wow! We minded your response'});
            // alert("We minded your request. will be back soon");
         },1000);
    // }
}

export const createNewTicket = async(payload, clearFeild, setLoading, setOpenTicket, setError) => {
    const { data, error } = await supabase
      .from('demo-feedback')
      .insert(payload)
    if (error) {
        clearFeild('');
        console.log(error);
        setTimeout(() => {
            setLoading(false);
            setError({success: false, message: 'Oops! We missed, can you try again.'});
        }, 1000);
        return
    }
    clearFeild('');
    setTimeout(() => {
        setLoading(false);
        setError({success: true, message: 'wow! We minded your response'});
    },1000);
}