import React, { useEffect, useState } from 'react'
import FullLayout from './Layout/FullLayout'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';
import Pricing from './Pages/Pricing';
import About from './Pages/About';
import PNF from './Pages/404';
import './Assets/Style/Custom.css';
import Copyrights from './Pages/Copyrights';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import ContactUs from './Pages/ContactUs';

function PublicRouter() {
  return (
      <Router>
          <Routes>
            <Route path="/" element={<FullLayout />}>
              <Route path={""} index element={<Home />}/>
              <Route path={"*"} element={<PNF />}/>
              <Route path={"pricing"} index element={<Pricing />} />
              <Route path={"about"} index element={<About />} />
              <Route path={"help&support"} index element={<ContactUs />} />
              <Route path={"copyrights"} index element={<Copyrights />} />
              <Route path={"privacy_ploicy"} index element={<Privacy />} />
              <Route path={"terms_of_use"} index element={<Terms />} />
            </Route>
          </Routes>
      </Router>
  )
}

export default PublicRouter