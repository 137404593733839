import React from 'react'

function Privacy() {
    return (
        <div>
            <div>
                <div className="container px-4 py-2 my-2 text-center">
                    <h1 className="display-5 fw-bold c-balck">Privacy & Policy for <br/><span className='icon-title'>vernacle</span></h1>
                </div>
                <div style={{ color: "whitesmoke" }} className="container mx-auto px-4">
                    <div className='my-3'>
                        <span>Effective Date: February 1st, 2023</span>
                    </div>
                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>1. Introduction:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> At Vernacle, we are committed to protecting your privacy and personal data. Our privacy policy outlines the types of information we collect and how we use it to provide you with the best possible service.</p>
                    </div>

                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>2. Information Collection and Use:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> We collect personal information such as your name, email address, and company information when you register for our SaaS product. This information is used to set up your account, provide customer support, and send promotional materials if you choose to receive them.
                            We may also collect information about your usage of our SaaS product, such as pages visited and features used. This information is used to improve the user experience and provide personalized support.
                        </p>
                    </div>

                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>3. Cookies:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> Our SaaS product uses cookies to track usage and improve your experience. You can choose to disable cookies in your web browser, but doing so may limit some of the features of our SaaS product.</p>
                    </div>

                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>4. Data Retention:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> We will retain your personal information for as long as your account is active or as needed to provide you with our services. If you choose to cancel your account, we will delete your personal information unless we are required by law to retain it.</p>
                    </div>

                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>5. Data Security:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> We take data security seriously and use appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, or misuse.</p>
                    </div>
                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>6. Data Transfer:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> Your personal information may be transferred to and processed in countries other than your own, including countries that may have different data protection laws. When we transfer your personal information, we take steps to ensure that it is protected and that your rights under this privacy policy are respected.</p>
                    </div>
                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>7. Your Rights:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> You have the right to access, correct, and delete your personal information. You also have the right to restrict or object to our processing of your personal information. If you have any concerns about how we handle your personal information, please contact us.</p>
                    </div>
                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>8. Changes to this Privacy Policy:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> We may update this privacy policy from time to time to reflect changes in our practices or to comply with legal requirements. We will notify you of any material changes to this privacy policy by posting the updated policy on our website.</p>
                    </div>
                    <div className='py-4 col-9'>
                        <b style={{ fontSize: 18, }}>9. Contact Us:</b>
                        <p style={{ fontSize: 18, lineHeight: 2 }}> If you have any questions about this privacy policy, please contact us at privacy@vernacle.in.</p>
                    </div>
                    <div className='py-4 col-9'>
                        {/* <b style={{fontSize: 18,}}>9.Governing Law:</b> */}
                        <p style={{ fontSize: 18, lineHeight: 2 }}> This privacy policy was last updated on February 1st, 2023.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy